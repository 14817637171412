import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Pixel Bomber`}</h2>
    <p><a parentName="p" {...{
        "href": "/games/pixelbomber"
      }}>{`Pixel Bomber`}</a>{` is a simplistic browser-based multiplayer game that lets you battle anyone anywhere for the leaderboard.
`}<a parentName="p" {...{
        "href": "http://pixel.programmer5000.com"
      }}>{`Play it`}</a></p>
    <h2>{`The Andromeda series`}</h2>
    <p><a parentName="p" {...{
        "href": "/games/TA1"
      }}>{`TA1`}</a>{`
`}<a parentName="p" {...{
        "href": "/games/TA2"
      }}>{`TA2`}</a>{`
`}<a parentName="p" {...{
        "href": "/games/TA3"
      }}>{`TA3`}</a>{`
`}<a parentName="p" {...{
        "href": "/games/TA4"
      }}>{`TA4`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      